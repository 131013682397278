module.exports = {
    _pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
    _title: 'Gatsby Starter - Prismic.io', // Navigation and Site Title
    _titleAlt: 'Prismic Starter', // Title for JSONLD
    description:
        'A bright single-page portfolio starter with big typography & images for Gatsby.',
    _url: 'https://gatsby.spell.codes', // Domain of your site. No trailing slash!
    siteLanguage: 'en', // Language Tag on <html> element
    logo: '/logos/logo-1024.png', // Used for SEO

    // JSONLD / Manifest
    favicon: 'src/favicon.png', // Used for manifest favicon generation
    shortName: 'Prismic', // shortname for manifest. MUST be shorter than 12 characters
    author: 'Emanuele Tuttolani', // Author for schemaORGJSONLD
    themeColor: '#3D63AE',
    backgroundColor: '#EBEDF2',

    twitter: '@mnlttt92', // Twitter Username
};
