import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'react-emotion';
import {
    Layout,
    Listing,
    Wrapper,
    SliceZone,
    Title,
    SEO,
    Header,
} from 'components';
import Categories from '../components/Listing/Categories';
import website from '../../config/website';

const Hero = styled.section`
    background-color: ${props => props.theme.colors.greyLight};
    padding-top: 1rem;
    padding-bottom: 4rem;
`;

const Headline = styled.p`
    font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont',
        'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    color: ${props => props.theme.colors.grey};
    font-size: 1.25rem;
    a {
        font-style: normal;
        font-weight: normal;
    }
`;

const Post = ({ data: { prismicPost, posts }, location }) => {
    const { data } = prismicPost;
    let categories = false;
    if (data.categories[0].category) {
        categories = data.categories.map(c => c.category.document[0].data.name);
    }
    return (
        <Layout>
            <SEO
                title={`${data.title.text} | ${website._title}`}
                pathname={location.pathname}
                article
            />
            <Hero>
                <Wrapper>
                    <Header />
                    <Headline>
                        {data.date} —{' '}
                        {categories && <Categories categories={categories} />}
                    </Headline>
                    <h1>{data.title.text}</h1>
                </Wrapper>
            </Hero>
            <Wrapper>
                <SliceZone allSlices={data.body} />
                <Title style={{ marginTop: '4rem' }}>Recent posts</Title>
                <Listing posts={posts.edges} />
            </Wrapper>
        </Layout>
    );
};

export default Post;

Post.propTypes = {
    data: PropTypes.shape({
        prismicPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
    query PostBySlug($uid: String!) {
        prismicPost(uid: { eq: $uid }) {
            uid
            data {
                title {
                    text
                }
                date(formatString: "DD.MM.YYYY")
                categories {
                    category {
                        document {
                            data {
                                name
                            }
                        }
                    }
                }
                body {
                    ... on PrismicPostBodyText {
                        slice_type
                        id
                        primary {
                            text {
                                html
                            }
                        }
                    }
                    ... on PrismicPostBodyCodeBlock {
                        slice_type
                        id
                        primary {
                            code_block {
                                html
                            }
                        }
                    }
                    ... on PrismicPostBodyQuote {
                        slice_type
                        id
                        primary {
                            quote {
                                html
                                text
                            }
                        }
                    }
                    ... on PrismicPostBodyImage {
                        slice_type
                        id
                        primary {
                            image {
                                localFile {
                                    childImageSharp {
                                        fluid(maxWidth: 1200, quality: 90) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        posts: allPrismicPost(
            limit: 2
            sort: { fields: [data___date], order: DESC }
        ) {
            edges {
                node {
                    uid
                    data {
                        title {
                            text
                        }
                        date(formatString: "DD.MM.YYYY")
                        categories {
                            category {
                                document {
                                    data {
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
